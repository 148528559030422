import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LOCALE_LANGUAGE } from './utils/constants';

i18n
    .use(initReactI18next)
    .init({
        lng: DEFAULT_LOCALE_LANGUAGE, // Default language
        fallbackLng: DEFAULT_LOCALE_LANGUAGE, // Fallback language
        resources: {
            enUS: {
                //@American English
                translation: require('./locales/en-US.json'),
            },
            enGB: {
                //@British English
                translation: require('./locales/en-GB.json'),
            },
            fiFI: {
                //@Finnish (Finland)
                translation: require('./locales/fi-FI.json'),
            },
            frFR: {
                //@Standard French
                translation: require('./locales/fr-FR.json'),
            },
            deDE: {
                //@German (Germany)
                translation: require('./locales/de-DE.json'),
            },
            esES: {
                //@European Spanish (Spain)
                translation: require('./locales/es-ES.json'),
            },
            es419: {
                //@Latin American Spanish
                translation: require('./locales/es-419.json'),
            },
            itIT: {
                //@Italian (Italy)
                translation: require('./locales/it-IT.json'),
            },
            nlNL: {
                //@Dutch (Netherlands)
                translation: require('./locales/nl-NL.json'),
            },
            daDK: {
                //@Danish (Denmark)
                translation: require('./locales/da-DK.json'),
            },
            koKR: {
                //@Korean (South Korea)
                translation: require('./locales/ko-KR.json'),
            },
            zhTW: {
                //@Traditional Chinese (Taiwan)
                translation: require('./locales/zh-TW.json'),
            },
        },
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;