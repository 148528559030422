import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { addMessageListener, MessagingContract, sendMessage } from "@hcecbp/uiintegrationcomponent";

import RbmRoutes from "./RbmRoutes";

import { ViewTypeProps, AppContextPayload, OrgListProps } from "../../models";

import { getThemeName } from "../../utils/utility";

import { ConfigurationVariables } from "../../config";
import { IAM_PROJECT_ID_SESSION_TOKEN_STORAGE_NAME, RBM_ACCESS_TOKEN_STORAGE_NAME } from "../../utils/constants";
import { useGetOrgListsQuery } from "../../services/rbm-api";

import { IBuildOrganisation } from "../../models/devicelist";
import { RootState } from "../../store";
import { setOrganizations, setRBMDetails } from "../../actions";

const RbmApplication = (props: { type: string }) => {
    console.log("RBM Application: ", props);
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const [tempTheme, setTempTheme] = useState("0");
    const [tempLocale, setTempLocale] = useState("en-US");

    const { selectedOrg, rbmAccessToken } = useSelector((state: RootState) => state.global);

    const { data: orgDataLists } = useGetOrgListsQuery("", {
        skip: !selectedOrg || !rbmAccessToken
    });

    // Function to handle 'appContext' messages received from the parent window
    function handleAppContext(payload: AppContextPayload) {
        try {
            const tempViewType: ViewTypeProps = JSON.parse(payload.viewType);
            console.log('View Type:', tempViewType);

            setTempTheme(tempViewType.theme);
            setTempLocale(tempViewType.locale);

            dispatch(setRBMDetails({
                selectedOrg: tempViewType.selectedOrg || '',
                rbmAccessToken: tempViewType.rbmAccessToken || ''
            }));

            sessionStorage.setItem(RBM_ACCESS_TOKEN_STORAGE_NAME, tempViewType.rbmAccessToken || '');
            sessionStorage.setItem(IAM_PROJECT_ID_SESSION_TOKEN_STORAGE_NAME, tempViewType.selectedOrg || '');
        } catch (error) {
            console.error('Error parsing app context:', error);
        }
    }

    // // Function to process messages which type is 'appContext'
    const processMessage = (message: MessagingContract) => {
        console.log('Received message in App.tsx:', message);

        if (message.type === "appContext") {
            handleAppContext(message.payload as AppContextPayload);
        }
    };

    useEffect(() => {
        const event = addMessageListener(ConfigurationVariables.RBM_PORTAL_HOST_URL, processMessage);

        return () => {
            if (event) {
                event.dispose();
            }
        };
    }, []);

    useEffect(() => {
        // Access the <html> tag  
        sendMessage(
            ConfigurationVariables.RBM_PORTAL_HOST_URL,
            {
                type: "appContext",
                payload: {
                    viewType: JSON.stringify({ iotLoaded: true }),
                },
            },
            window.parent
        );
    }, []);

    useEffect(() => {
        // Access the <html> tag  
        const gatewayElement = document.documentElement;

        if (tempTheme === "0") {
            gatewayElement.classList.remove('light');
            gatewayElement.classList.add('dark');
        } else {
            gatewayElement.classList.remove('dark');
            gatewayElement.classList.add('light');
        }
        // Cleanup function to remove the class when the component unmounts
        return () => {
            gatewayElement.className = '';
            if (tempTheme === "0") {
                gatewayElement.classList.add('light');
                gatewayElement.classList.remove('dark');
            }
            else {
                gatewayElement.classList.add('dark');
                gatewayElement.classList.remove('light');
            }
        };
    }, [tempTheme]);

    useEffect(() => {
        if (tempLocale) {
            i18n.changeLanguage(tempLocale.replace("-", ""));
        }
    }, [tempLocale]);

    useEffect(() => {
        if (orgDataLists?.resources?.length > 0) {
            const nonSIOrgLists: OrgListProps[] = [];

            orgDataLists?.resources?.forEach((item: IBuildOrganisation) => {
                if (!item.zuoraAccountNo) {
                    nonSIOrgLists.push({
                        text: item.name,
                        value: item.id
                    });
                }
            });

            dispatch(setOrganizations({ orgLists: nonSIOrgLists }));
        }
    }, [orgDataLists]);

    return (
        <BrowserRouter>
            <section className={`theme-${getThemeName(tempTheme)} iotdevices-container`}>
                <RbmRoutes />
            </section>
        </BrowserRouter>
    );
};

export default RbmApplication;