import React, { useEffect } from "react";
import { IAM_PROJECT_ID_SESSION_TOKEN_STORAGE_NAME, RBM_ACCESS_TOKEN_STORAGE_NAME } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getThemeName } from "../../utils/utility";
import { OidcConfig } from "../../oidcConfig";
import { OidcProvider } from "@axa-fr/react-oidc";
import { BrowserRouter } from "react-router-dom";
import StandAloneRoutes from "./StandAloneRoutes";
import { RootState } from "../../store";
import { useGetOrgListsQuery } from "../../services/rbm-api";
import { OrgListProps } from "../../models";
import { IBuildOrganisation } from "../../models/devicelist";
import { setRBMAccessToken } from "../../actions";

const StandaloneApplication = (props: { type: string }) => {
    console.log("Standalone Application: ", props);
    const dispatch = useDispatch();
    const { selectedOrg, rbmAccessToken } = useSelector((state: RootState) => state.global);
    const { data: orgDataLists } = useGetOrgListsQuery("", {
        skip: !!selectedOrg || !rbmAccessToken,
    });

    useEffect(() => {
        if (orgDataLists?.resources?.length > 0) {
            const siOrgLists: OrgListProps[] = [];
            const nonSIOrgLists: OrgListProps[] = [];

            orgDataLists?.resources?.forEach((item: IBuildOrganisation) => {
                if (item.zuoraAccountNo) {
                    siOrgLists.push({
                        text: item.name,
                        value: item.id
                    });
                } else {
                    nonSIOrgLists.push({
                        text: item.name,
                        value: item.id
                    });
                }
            });

            dispatch({
                type: 'SET_ORG_LIST', payload: {
                    orgLists: nonSIOrgLists,
                    selectedOrg: siOrgLists[0]?.value || ""
                }
            });
            
            sessionStorage.setItem(IAM_PROJECT_ID_SESSION_TOKEN_STORAGE_NAME, siOrgLists[0]?.value || "");
        }
    }, [orgDataLists]);

    useEffect(() => {

        const tokenVal = JSON.parse(sessionStorage.getItem("oidc.default") || '{}')?.tokens?.accessToken || '';

        sessionStorage.setItem(RBM_ACCESS_TOKEN_STORAGE_NAME, tokenVal);

        dispatch(setRBMAccessToken({ rbmAccessToken: tokenVal }));

    }, [sessionStorage.getItem("oidc.default")]);

    return (
        <BrowserRouter>
            <section className={`theme-${getThemeName("1")} iotdevices-container`}>
                <OidcProvider configuration={OidcConfig}>
                    <StandAloneRoutes />
                </OidcProvider>
            </section>
        </BrowserRouter>
    )
}

export default StandaloneApplication;   