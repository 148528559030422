// @TODO: Add to translations file once translations are available
export const config = {
  download: "Download",
  runDiagnosticsButtonLabel: "Run Diagnostics",
  triggerTime: "Trigger Time",
  status: "Status",
  runDiagnosticsModalTitle: "Run diagnostics",
  runDiagnosticsModalContent:
    "Enabled diagnostics will generate a new report. The previous report will be replaced and removed. Are you sure you want to proceed?",
  requestLogs: "Request Logs",
  logs: "Logs",
  ConfigData: "Config Data",
  fetchConfigData: "Fetch Config Data",
  fetchConfigDataModalContent:
    "Fetching Config Data will generate a new report. The previous report will be replaced. Are you sure you want to proceed?",
  remoteAccessAndMonitor: "Remote Monitor",
  goToAzureArcPortalLink: "Go to Portal",
  remoteAccessAndMonitorDesc:
    "Provides capability to remote into the Gateway machine. Also provides Gateway health and logs monitoring functionalities.",
  regenerateKeyDesc:
    "Please use the registration key below to finish device configuration for",
  generateNewKeyButtonText: "Generate new key",
  copyText: "Copy",
  copiedText: "Copied!",
  expiresOnText: "Expires on ",
  expiredText: "Expired",
  loadingDiagnosticText: "Loading diagnostics data...",
  errorDiagnosticText: "Error loading diagnostics data. Please try again.",
  noConfigFilesFound: "No Config data available",
  anErrOccurred: "An error occurred",
  diagnostics: "Diagnostics",
  noDiagnosticsFilesFound: "No Diagnostics reports available",
  configDataDesc:
    "Fetches the latest config data (Abstract Folder of the supervisor) from the gateway.",
  diagnosticsDesc:
    "Invokes the supervisor to collect and provide the diagnostics package (DCT package, connectivity reports, nameplates, license info & Point Files)"
};
